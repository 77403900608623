﻿.navbar {
    position: absolute;
    z-index: 1000;
    width: 100%;
    top: 0px;
    border-bottom: 1px solid #fff;
    background-color: black;
}
.navbar {
    font-family: 'Oswald', sans-serif;
    font-size: 25px;
}
.navbar-brand {
    top: 1%;
    left: 50%;
    margin-left: -3rem;
    position: absolute;
    @include media-breakpoint-up(lg) {
        margin-left: 2rem;
        left: 7%;
    }
}

    @media (min-width: 992px) {
        .navbar-collapse {
            margin-top: -6rem;
        }

        @media screen and (min-width:992px) {
            .nav-item, .float-left {
                float: left;
            }
        }

        ul .float-right {
            float: right;
        }

        ul.navbar-nav {
            width: 50% !important;
            display: block;
        }

        ul.navbar-nav:first-child {
            margin-right: 100px;
        }
    }

    @media screen and (max-width:992px) {
        #navbarNav {
            display: none;
        }
    }

    @media screen and (max-width:992px) {
        #main-nav .collapse.show {
            display: block !important;
            height: 100vh;
            margin-top: 30%;
        }
    }

    .bg-dark {
        background-color: #000000 !important;
    }

    .logo {
        width: 105px;
        height: 105px;
    }

    @include media-breakpoint-up(lg) {
        .logo {
            width: 200px;
            height: 200px;
        }
    }

    .divider {
        margin: 6px;
    }

    @media (min-width: 992px) {
        .navbar-expand-lg .navbar-nav .nav-link {
            padding-right: .5rem;
            padding-left: .5rem;
        }
    }

    .nav-link {
        text-transform: uppercase;
    }

    .nav-link {
        position: relative;
        width: 100%;

        .arrow {
            width: 25px;
            height: 25px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 11;
            fill: black;
        }
    }

    .navbar {
        .social {
            display: block;
            width: 100%;
            list-style: none;
            a {
                width: 20px;
                height: 20px;
                color: white;
                display: inline-block;

                &:hover {
                    color: #ccc;
                }
            }
        }
    }

.fa-instagram {
    width: 30px;
}
.fa-youtube{
    width:40px;
}

.triangles {
    display: none;
}

    @include media-breakpoint-up(lg) {
        .page-wrapper {
            margin-left: 300px;
        }

        .navbar {
            position: fixed;
            top: 0;
            left: 0;
            width: 300px;
            height: 100%;
            overflow: hidden;
            background: black;
            z-index: 10;
        }

        ul.navbar-nav {
            margin-right: 0 !important;
            width: 100% !important;
        }

        .nav-item {
            width: 100%;
        }

        .navbar-collapse {
            flex-direction: column;
            align-items: flex-start;
        }

        .triangles {
            display: block;
            width: 300px;
            height: 355px;
            position: absolute;
            bottom: 5%;
            right: -1px;
            z-index: 10;
            pointer-events: none;
            user-select: none;
        }
    }
    .main {
        width: 75%;
        margin: 25px auto;
    }
.form-control {
    width: 80%;
}
    .has-search .form-control {
        padding-left: .5rem;
    }
.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.4rem;
    line-height: 2.375rem;
    text-align: center;
    color: #fff;
    right: 15%;
}
    .form-control {
        background-color: #0e0e0e;
        border: 1.5px solid #0e0e0e;
    }
    .form-control:focus {
        color: #fff;
        background-color: #0e0e0e;
        border-color: #7bff00;
        box-shadow: 0 0 0 0.2rem #7bff003d;
    }

i.fas.fa-search:hover{
    color: #7bff00;
}
nav.navbar.navbar-expand-lg.navbar-dark {
    position: fixed;
}