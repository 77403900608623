﻿body {
    color: #fff;
    font-size: 1.1rem;
    font-family: 'Roboto', sans-serif;
    background: #0e0e0e;
}
// For side nav
/*@media (min-width:768px) {
    .page-wrapper {
        margin-left: 232px;
        overflow: hidden;
    }
}*/
@media (min-width:768px) {
    body {
        padding-top: 0
    }
}
img {
    width: 100%;
    height: auto
}
.overflow-hidden{
    overflow:hidden;
}
@include media-breakpoint-up(md) {
    .padding-full {
        padding-left: 12%;
        padding-right: 12%;
    }
}
.featurette-divider {
    padding: 25px 0;
    display: block;
    position: relative;
}
.footer {
    background-color: #000 !important;
    border-bottom: 1px solid white;
}
.footer-bleed {
    background-color: #0e0e0e;
    height: 300px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.footer-divider {
    margin: 0 8px 0 8px;
}
ul{
    padding-left:0px;
}
.justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}
#cookie-consent {
    background: #000;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    p{
        margin-bottom:0!important;
    }
}