﻿#mapwrapper {
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    margin: 0 auto;
    //margin-left: -60%;
    padding: 0 0;
    background-color: transparent;
    min-width: 220px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.map-crest{
    width: 5%;
}
#mapbase svg {
    max-height: 100%;
    width: 100%;
    margin: 0;
    -webkit-filter: drop-shadow( 1px 1px 2px rgba(0, 0, 0, 0.2) );
    filter: drop-shadow( 1px 1px 2px rgba(0, 0, 0, 0.2) );
}

#ukjstip {
    font: 14px/16px Trebuchet MS, Helvetica, Arial, sans-serif;
    display: none;
    max-width: 40%;
    padding: 5px;
    border: 1px solid #404040;
    color: #404040;
    z-index: 1000;
    float: left;
    position: absolute;
    background: rgba(265, 265, 265, 0.9);
    word-break: keep-all;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
}

#ukjstip p {
    margin: 0 !important;
    color: #404040 !important;
}

#ukjstip img {
    float: left;
    padding: 3px;
    max-width: 100%;
}

/* THIS IS TO HIDE AN AREA IF NEEDED
#ukjs2, #ukjsvn2 {
  display: none;
}*/
.modal-header {
    color: #fff;
}
.modal-content {
    background-color: #000;
    border: 1.5px solid #36ff00;
}
.close {
    color: #fff;
    opacity: 1;
}
.close:hover {
    color: #36ff00;
}
.modal-header {
    border-bottom: 1.5px solid #383838;
}
@media(min-width:990px){
    .is-map{
        margin-left:-6rem;
        position:relative;
    }
}