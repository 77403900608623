﻿h1 {
    font-size: 2.5rem;
    @include media-breakpoint-up(lg) {
        font-size: 3rem;
    }
 }
h1, h2, h3 {
    text-transform: uppercase;
    margin-bottom: 20px;
}
a, .btn-link {
    color: #55950d;
    text-decoration: none;
}

a:hover, .btn-link:hover {
    color: #7bff00;
    text-decoration: none;
}

h1, h2, h3, h4, h4, .navbar.fixed-left, .page-title {
    font-family: 'Staatliches', sans-serif;
    font-weight: normal;
}
.btn-link:focus {
    text-decoration: none;
}
.btn {
    background-color: #000000;
    border-color: #55950d;
    border-radius: .25rem;

    &:hover {
        color: #000;
        background-color: #7bff00;
        border-color: #7bff00;
    }
}
.factfilePoint {
    font-family: 'Oswald', sans-serif;
    margin-bottom:10px;
}
 h1, h2, h3, h4, h5, h6 {
    line-height: 1!important;
}