﻿.top-hero {
    width: 100%;
    height: 50vh;
    position: relative;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    overflow: hidden;
    border-bottom: 1px solid white;
/*    margin-bottom: 2rem;
*/    margin-top: 13%;

    @include media-breakpoint-up(st) {
        margin-top: 6%;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 0%;
    }

    .top-hero-img {
        position: absolute;
        /*        top: 4%;
*/ left: 0;
        width: 100%;
        height: 100%;
        border-bottom: 1px solid #fff;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: "Object-fit:cover";
        }
    }
}

    @include media-breakpoint-up(xl) {
        .top-hero {
            height: 80vh;
        }
    }

    .top-hero-img::after {
        content: " ";
        /*        background: black;
*/ position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*        opacity: .5;
*/ z-index: 1;
    }

    .top-hero-img.has-no-title::after {
        background: none;
    }

.page-title {
    position: absolute;
    top: 35%;
    /* right: 2%;*/
    text-align: center;
    /*left: 2%;*/
    width: 100%;
    z-index: 2;
    font-size: 12vw;
    @include media-breakpoint-up(lg) {
        font-size: 6vw;
    }
    text-shadow: 2px 2px #000;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px);
    border: 2px;
    border-radius: 10px;
    padding: 10px;
}
    /*.counter-wrapper:before {
    display: block;
    height: 99.9%;
    width: 100%;
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    background: #000;
    background: linear-gradient(1turn,#000 10%,hsla(0,0%,100%,0));
    z-index: 0;
}*/
