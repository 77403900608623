﻿.featurette-divider::after {
    content: " ";
    height: 1px;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    background: rgba(225,225,225,0.2);
}
.feature-panel {
    position: relative;
    overflow: hidden;
    display: block;
    border: 1px solid #fff;

    .h2 {
        color: #fff;
        font-size: 25px;
        text-shadow: 2px 2px #000;

        &:hover {
            color: #fff;
        }
    }

    .h1 {
        color: #fff;
        text-shadow: 2px 2px #000;

        &:hover {
            color: #fff;
        }
    }

    @include media-breakpoint-up(lg) {
        .h2 {
            font-size: 34px;
        }
    }

    &:before {
        content: " ";
        display: block;
        width: 100%;
        padding-bottom: 150%;
    }

    &__txt {
        position: absolute;
        bottom: 3%;
        right: 3%;
        z-index: 3;
        padding-left: 10px;
    }

    &__txt--next {
        display: inline-block;
        margin-left: 15px;
        vertical-align: text-bottom;
    }

    &:after {
        content: " ";
        background: rgba(0, 0, 0, 0.40);
        transition: 1s all ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    &:hover {
        &:after {
            background: rgba(0,0,0,0.15);
        }
        /*img {
            transform: scale(1) translateZ(0);
        }*/
    }

    &__inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            object-fit: cover;
            font-family: "Object-fit:cover";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /*transform: scale(1.333) translateZ(0);
            transition: 5s all ease;*/
        }
    }
}
.card {
    margin-top: 10px !important;
    background-color: #212121;
    color: #ffffff;
    border: none;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: .25rem;
}
.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.card-header {
    margin-bottom: 0;
    background-color: rgba(0,0,0,0.03);
    border-bottom: 1px solid rgba(0,0,0,0.125);
}
.card-body {
    background-color: #000000ad;
    padding: 20px 20px !important;
}
svg.svg-inline--fa.fa-futbol {
    width: 65%;
}
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}
.embed-responsive:before {
    display: block;
    content: "";
}
.container-google-map {
    overflow: hidden;
}

.sidepanel {
    padding: 40px;
    background-color: #060606;
    border-radius: 5px;
    border: 1px solid #656565;
}
.panel {
    display: block;
    padding: 1rem;
    position: relative;
}
.panel-crest {
    position: absolute;
    top: 5%;
    right: 5%;
    width: 28%;
    z-index: 100;
    @include media-breakpoint-up(md) {
        width: 20%;
    }
}
.blog-panel {
    display: block;
    background-color: #060606;
    border-radius: 5px;
    border: 1px solid #fff;
}
.blog-panel-content{
    padding:1rem;
}
.sidepanel {
    position: relative;
    overflow: hidden;

    .triangles {
        position: absolute;
        top: -80px;
        width: 100%;
    }

    > * {
        position: relative;
        z-index: 1;
    }
}
.search-btn {
    color: #fff;
    background-color: #000;
    border: none;
}
.grounds-crest-logo {
    @media(max-width:575px) {
        position: absolute;
        top: -26%;
        right: 0px;
    }

    @media(min-width:576px) and (max-width:992px) {
        position: absolute;
        top: -40%;
        right: 0px;
    }

    img {
        @media(max-width:992px) {
            width: 100%;
        }
    }
}
.is-counter {
    text-align: center;
    /*margin-top: -17%;*/
    /*        margin-bottom: 7%;
*/ font-family: 'Staatliches', cursive;
}

.is-counter .counter {
    font-size: 40px;
    line-height:1;
    z-index: 2;
    text-shadow: 2px 2px #000;
}

p.counterText {
    font-size: 20px;
    //margin-top: -6%;
    text-shadow: 2px 2px #000;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
        font-size: 30px;
    }
}

.feature-row {
    background-color: #000;
    color: #fff;
    padding: 15px;
    border-bottom: 1px solid #fff;
    //height: 20vh;
}

.page-link {
    padding: 0.5rem 1rem;
    color: #ffffff;
    background-color: #000;
    border: 1.5px solid #ffffff;
}
.page-item.active .page-link {
    background-color: #55950d;
    border-color: #55950d;
}
.page-link:hover {
    color: #000000;
    background-color: #7bff00;
    border-color: #7bff00;
}

/* Zoom In Images */
.blog-panel figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    height: 100%;
}
.blog-panel figure:hover img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}
figure {
    width: 100%;
    overflow: hidden;
}
.breadcrumb{
    margin-bottom:1rem!important;
}
#to-top-wrapper {
    display: inline-block;
    position: fixed;
    bottom: 7%;
    right: 15px;
    z-index: 9000;
    text-align: left;
}
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem #7bff004a;
}
#to-top-wrapper #to-top-button {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0 4px;
    width: auto;
    height: 35px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    will-change: transform;
    transform: translate3d(0,40px,0);
    transition: transform .3s ease-in-out;
    background-color: #000000;
    color: #fff;
    border:none;
}
.page-scrolled #to-top-wrapper #to-top-button {
    transform: translate3d(0,-30px,0);
}

svg:not(:root).svg-inline--fa {
    overflow: visible;
}
.svg-inline--fa.fa-w-12 {
    width: .75em;
}
#to-top-wrapper #to-top-button span {
    display: block;
    margin-top: -5px;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
}
.form-control {
    background-color: #000000;
    border: 1.5px solid #ffffff;
}
.btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
    color: #fff;
    background-color: #000000 !important;
    border-color: #000000 !important;
}
@include media-breakpoint-down(md) {
    .btn-primary {
        margin-top: 20px;
    }
}
.sitemap ul {
    padding-left: 40px!important;
}
.mt-10{
    margin-top:5rem;
}