﻿// Modified (and only modified) variables live here

// Bootstrap makes use of the !default flag so it will only assign a variable if it does not already exist.
// If we define those variables here first then these values will overwrite bootstrap's default configuration.
// This is much better. It keeps our code maintenance down and makes point-release upgrades much easier.

// Copy variables across from /node_modules/bootstrap/scss/_variables.scss as necessary e.g.
//$white:    #000 !default;

// Assign to bootstrap Sass variables

// Components

$border-width: 1.5px !default;


// Color system

$black: #000;
$white: #fff;
$light: #f1f3f7;
$purple: #7b4192;
$orange: #EB5E1D;
$green: #6cb646;

$primary: $purple;
$secondary: $orange;

// Typography
$font-family-sans-serif: 'Source Sans Pro',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-weight: 700 !default;

$link-color: $secondary !default;

// Cards

$card-border-color: none;
$card-spacer-x: 0;

// Navs

$navigation-height: 90px;
$navbar-nav-link-padding-x: 1.5rem !default;

// Navbar

$navbar-toggler-padding-y: .25rem !default;
$navbar-toggler-padding-x: .75rem !default;
$navbar-light-color: black !default;
$navbar-light-toggler-border-color: none !default;

$navbar-light-hover-color: rgba($black, .5) !default;
$navbar-light-active-color: rgba($black, .6) !default;

// Buttons

$btn-border-radius: 2rem !default;
$btn-border-radius-lg: 2.4rem !default;
$btn-border-radius-sm: 1.6rem !default;

$input-btn-padding-x-sm: 1.5rem !default;
$input-btn-padding-x-lg: 3rem !default;

$input-btn-padding-x: 1.75rem !default;

// Breadcrumbs

$breadcrumb-font-size: null !default;
$breadcrumb-padding-y: .25rem !default;
$breadcrumb-item-padding: .5rem !default;
$breadcrumb-margin-bottom: 0rem !default;
$breadcrumb-bg: none !default;

$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding: 0 !default;

