﻿/* ==========================================================================
    Horizontal scrolling image gallery
    ========================================================================== */

.horizontal-gallery {
	user-select: none;
	display: block;
	width: 100%;
	margin: 15px 0;
	padding: 15px 0;
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	border-bottom: 5px solid transparent;

	&::-webkit-scrollbar {
		margin: 0;
		background-color: transparent;
		height: 16px;
	}

	&::-webkit-scrollbar-track {
		height: 16px;
		border-radius: 8px;
		margin: 0 15px;
		background-color: #656565;
	}

	&::-webkit-scrollbar-thumb {
		box-sizing: border-box;
		border: 3px solid transparent;
		background-clip: padding-box;
		height: 16px;
		border-radius: 8px;
		background-color: rgba(black, 0.2);
		cursor: pointer;

		&:hover {
			background-color: #000;
		}
	}

	scrollbar-face-color: #000;
	scrollbar-arrow-color: #000;
	scrollbar-track-color: #fff;
	scrollbar-shadow-color: #000;
	scrollbar-highlight-color: #000;
	scrollbar-3dlight-color: #000;
	scrollbar-darkshadow-Color: #000;

	ul {
		display: block;
		position: relative;
		width: intrinsic; /* Safari/WebKit uses a non-standard name */
		width: -moz-max-content; /* Firefox/Gecko */
		width: -webkit-max-content; /* Chrome */
		width: -ms-max-content; /* IE */
		margin: 0 auto;
		padding: 0 15px;
		white-space: nowrap;
		list-style-type: none;
		list-style-position: outside;
		overflow: visible;
		text-align: center;

		li {
			display: inline-block;
			box-sizing: border-box;
			width: auto;
			margin: 0 15px 0 0;
			padding: 0;
			text-align: center;
			vertical-align: top;

			&:last-of-type {
				margin-right: 0;
			}

			&:before {
				content: '';
				display: none;
			}

			figure {
				display: block;
				box-sizing: border-box;

				a {
					background-color: #eee;
					display: block;
					box-sizing: border-box;
					transform: scale(1);
					transition: all 300ms ease-in-out;
					border: 4px solid #000;
					overflow: hidden;

					img {
						display: block;
						margin: 0;
						height: 285px;
						width: auto;
						max-width: none;

						@include media-breakpoint-up(lg) {
							height: 350px;
						}
					}

					&:hover, &:focus {
						transform: scale(1.05);
						border-color: #656565;
					}
				}
			}
		}
	}
}

// photoswipe style mods

.pswp {
	.pswp__caption__center {
		text-align: center;
		font-size: 1rem;
		line-height: 1.2rem;
	}
}