﻿.main-timeline {
    overflow: hidden;
    position: relative
}

.main-timeline .timeline {
    position: relative;
    margin-top: -19.5%
}
.main-timeline .timeline:first-child {
    margin-top: 0
}

.main-timeline .timeline-icon, .main-timeline .result {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}

.main-timeline .timeline:after, .main-timeline .timeline:before {
    content: "";
    display: block;
    width: 100%;
    clear: both
}

.main-timeline .timeline:before {
    content: "";
    width: 100%;
    height: 100%;
    box-shadow: -10px 0 5px -5px rgba(0,0,0,.5) inset;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2
}

.main-timeline .timeline-icon {
    width: 210px;
    height: 210px;
    border-radius: 50%;
    border: 25px solid transparent;
    z-index: 1;
    transform: rotate(45deg)
}

.main-timeline .result {
    display: block;
    width: 110px;
    height: 110px;
    line-height: 110px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 20px rgba(0,0,0,.4);
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    transform: rotate(-45deg)
}

.main-timeline .timeline-content {
    width: 35%;
    float: right;
 margin: 50px 0;
    z-index: 1;
    position: relative
}
.main-timeline .timeline-content .panel-text {
    padding: 30px 20px;
}

.main-timeline .timeline-content:before {
    content: "";
    width: 20%;
    height: 15px;
    position: absolute;
    top: 50%;
    left: -20%;
    z-index: -1;
    transform: translateY(-50%)
}

.main-timeline .title {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin: 0 0 10px
}

.main-timeline .description {
    font-size: 16px;
    color: #fff;
    line-height: 24px;
    margin: 0
}

.main-timeline .timeline:nth-child(2n):before {
    box-shadow: 8px 0 5px -5px rgba(0,0,0,.5) inset
}

.main-timeline .timeline:nth-child(2n) .timeline-icon {
    transform: rotate(-135deg);
    border-top-color: #e97e2e;
    border-right-color: #e97e2e
}

.main-timeline .timeline:nth-child(2n) .result {
    transform: rotate(135deg);
    color: #e97e2e
}

.main-timeline .timeline:nth-child(2n) .timeline-content {
    float: left
}

.main-timeline .timeline:nth-child(2n) .timeline-content:before {
    left: auto;
    right: -20%
}

.main-timeline .timeline:nth-child(2n) .timeline-content, .main-timeline .timeline:nth-child(2n) .timeline-content:before {
    background: #e97e2e
}

@media only screen and (max-width:1199px) {
    .main-timeline .timeline {
        margin-top: -103px
    }

    .main-timeline .timeline-content:before {
        left: -18%
    }

    .main-timeline .timeline:nth-child(2n) .timeline-content:before {
        right: -18%
    }
}

@media only screen and (max-width:990px) {
    .main-timeline .timeline {
        margin-top: -127px
    }

    .main-timeline .timeline-content:before {
        left: -2%
    }

    .main-timeline .timeline:nth-child(2n) .timeline-content:before {
        right: -2%
    }
}

@media only screen and (max-width:767px) {
    .main-timeline .timeline {
        margin-top: 0;
        overflow: hidden
    }

    .main-timeline .timeline:before, .main-timeline .timeline:nth-child(2n):before {
        box-shadow: none
    }

    .main-timeline .timeline-icon, .main-timeline .timeline:nth-child(2n) .timeline-icon {
        margin-top: -30px;
        margin-bottom: 20px;
        position: relative;
        transform: rotate(135deg)
    }

    .main-timeline .timeline:nth-child(2n) .result, .main-timeline .result {
        transform: rotate(-135deg)
    }

    .main-timeline .timeline-content, .main-timeline .timeline:nth-child(2n) .timeline-content {
        width: 100%;
        float: none;
        border-radius: 0 0 20px 20px;
        text-align: center;
        margin: 0 auto
    }

    .main-timeline .timeline-content:before, .main-timeline .timeline:nth-child(2n) .timeline-content:before {
        width: 15px;
        height: 25px;
        position: absolute;
        top: -22px;
        left: 50%;
        z-index: -1;
        transform: translate(-50%,0)
    }
}
// Results //
.win {
    color: green!important;
}

.win-panel {
    background: green!important;

    &:before {
        background: green!important;
    }
}

.win-border {
    border-color: green green transparent transparent!important;
}

.draw {
    color: orange!important;
}

.draw-panel {
    background: orange !important;

    &:before {
        background: orange !important;
    }
}

.draw-border {
    border-color: orange orange transparent transparent !important;
}

.loss {
    color: red!important;
}

.loss-panel {
    background: red !important;

    &:before {
        background: red !important;
    }
}
.loss-border {
    border-color: red red transparent transparent !important;
}